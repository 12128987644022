import styled from '@emotion/styled';

type FlexContentProps = {
    grow?: number;
    shrink?: number;
    basis?: string | number;
    alignSelf?: 'start' | 'center' | 'end' | 'flex-start' | 'flex-end';
};

export const FlexContent = styled.div<FlexContentProps>(({ grow, shrink, basis, alignSelf }) => ({
    flexGrow: grow,
    flexShrink: shrink,
    flexBasis: basis,
    alignSelf: alignSelf,
}));

export default FlexContent;
